<template>
  <div>
    <div class="min-h-50 d-flex w-100 align-items-center justify-content-center" v-if="!hasSnapshot">
      <div class="text-center">
        <b-icon icon="journal-medical" class="h1" />
        <p class="my-3">
          Ainda não há informações sobre os
          <strong>Procesos Trabalhistas</strong>.
        </p>
        <b-button @click="handleSyncronize()" v-if="is_provider && enabled">
          <b-icon icon="arrow-down-up" class="mr-2" />
          <span>Sincronizar agora</span>
        </b-button>
      </div>
    </div>
    <div v-else>
      <div class="d-md-flex align-items-center justify-content-start">
        <small class="mr-2">
          Ultima atualização {{ updated_at | dateFull }}
        </small>
        <unicon v-if="is_provider && enabled" name="redo" @click="handleSyncronize()" class="pointer small" height="16"
          width="16" />
      </div>
      <small class="d-block mb-4">
        Os dados inseridos precisam ser fiéis
      </small>
      <div class="w-100 mr-4">
        <div>
          <strong>Processos por tribunais</strong>
        </div>
        <div class="mt-4" v-if="labor_lawsuits">
          <div class="w-100 d-flex justify-content-between align-items-center mb-3">
            <strong class="d-flex flex-1">Ações</strong>
            <strong class="d-flex flex-1"> Quantidade </strong>
            <strong class="d-flex flex-1"> Valor de Alçada (pretendido pelo recte) </strong>
            <strong class="d-flex flex-1"> Valor Risco Provável </strong>
            <strong class="d-flex flex-1"> SEFT %</strong>
          </div>
          <skeleton :rows="5" grid="1" v-if="loading" height="22px" />
          <div v-for="(labor, index) in labor_lawsuits" :key="index" class="w-100">
            <div class="
                d-flex
                justify-content-between
                align-items-center
                w-100
                pointer
              ">
              <strong class="d-flex flex-1 text-muted">
                <a class="
                pointer
              " v-b-popover.hover.top="'clique para ver a lista de processos'" @click="handleShowDetailLabor(labor)">{{
                labor.instance }}</a>
              </strong>
              <span class="d-flex flex-1 text-muted"> {{ labor.qtd }} </span>
              <span class="d-flex flex-1 text-muted">
                <money :disabled="!enabled || user.profile_id != 4" type="text" v-bind="maskMoney(labor.raised_value)" @blur.native="
                  handleChangeRow(labor, $event)
                  " v-model="labor.raised_value" class="table-field text-right w-75" />
              </span>
              <span class="d-flex flex-1 text-muted">
                <money :disabled="!enabled || user.profile_id != 4" type="text" v-bind="maskMoney(labor.risk_value)" @blur.native="
                  handleChangeRow(labor, $event)
                  " v-model="labor.risk_value" class="table-field text-right w-75" />
              </span>
              <span class="d-flex flex-1 text-muted">
                {{ (labor.seft * 100).toFixed(2) }}%
              </span>
            </div>
            <hr class="mt-1" />
          </div>
          <div class="w-100 d-flex justify-content-between align-items-center">
            <span class="d-flex flex-1">Total</span>
            <span class="d-flex flex-1"> {{ total.qtd }} </span>
            <span class="d-flex flex-1">
              <money :disabled="true" type="text" v-bind="maskMoney(total.raised_value)" v-model="total.raised_value"
                class="table-field text-right w-75" />
            </span>
            <span class="d-flex flex-1">
              <money :disabled="true" type="text" v-bind="maskMoney(total.risk_value)" v-model="total.risk_value"
                class="table-field text-right w-75" />
            </span>
            <span class="d-flex flex-1"> {{ (total.seft * 100).toFixed(2) }}% </span>
          </div>
        </div>
      </div>
    </div>

    <b-modal id="modal-detail-labor-lawsuits" size="xl" centered :title="currentLabor.description" hide-footer>
      <cards-list grid="5">
        <b-card v-for="(pr, index) in proccess" :key="index" class="mb-2 mr-2">
          <a :href="pr.jusbrasil_url" target="_blank">
            {{ pr.id }} - {{ pr.cr }}
          </a>
        </b-card>
      </cards-list>
    </b-modal>
  </div>
</template>
<script>
import { Money } from "v-money";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    Money,
  },
  data() {
    return {
      enabled: false,
      hasSnapshot: false,
      isEmpty: false,
      loading: false,
      activity: {},
      updated_at: "",
      service_id: "",
      provider_id: "",
      total: {},
      labor_lawsuits: [],
      proccess: [],
      currentLabor: {},
    };
  },

  computed: {
    ...mapGetters(["is_preview", "user", "preview_personify", "is_provider"]),
  },
  methods: {
    ...mapActions([
      "get_snapshot",
      "set_snapshot",
      "show_loading",
      "get_all_lawsuits",
    ]),

    handleChangeRow: _.debounce(function (
      labor,
      event,
    ) {
      event.stopPropagation();

      const raised_value = this.labor_lawsuits.reduce((acc, obj) => {
        return acc + obj['raised_value'];
      }, 0).toFixed(2);
      const risk_value = this.labor_lawsuits.reduce((acc, obj) => {
        return acc + obj['risk_value'];
      }, 0).toFixed(2);

      this.total.raised_value = raised_value
      this.total.risk_value = risk_value

      let data = {
        total: this.total,
        labor_lawsuits: this.labor_lawsuits
      }

      if (event.target?.value) {
        this.set_snapshot({
          type: "labor-lawsuits",
          service_id: this.service_id,
          provider_id: this.provider_id,
          data: data,
          method: 'patch'
        }).then(() => {
          this.getSnapshot();
        });
      }
    },
      50),

    maskMoney(value) {
      if (value >= 0) {
        return {
          decimal: ",",
          thousands: ".",
          prefix: "R$ ",
          suffix: "",
          precision: 2,
          masked: false,
        };
      }
      return {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: true,
      };
    },

    handleShowDetailLabor(act) {
      this.currentLabor = act;
      this.$bvModal.show("modal-detail-labor-lawsuits");
      this.get_all_lawsuits().then(({ data }) => {
        this.proccess = data.results;
      });
    },
    getSnapshot() {
      this.loading = true;
      this.show_loading({
        loading: true,
      });
      this.get_snapshot({
        type: "labor-lawsuits",
        service_id: this.service_id,
        provider_id: this.provider_id,
      }).then(({ data, updated_at, enabled }) => {
        this.enabled = enabled;
        if (!data) {
          return;
        }
        const { labor_lawsuits, total } = data;
        if (!updated_at) {
          this.hasSnapshot = false;
        } else {
          this.hasSnapshot = true;
          this.labor_lawsuits = labor_lawsuits;
          this.updated_at = updated_at;
          this.total = total;
        }
        this.show_loading({
          loading: false,
        });
        this.loading = false;
      });
    },
    handleSyncronize() {
      this.$bvModal.show("modal-generating-snapshot");
      this.set_snapshot({
        type: "labor-lawsuits",
        service_id: this.service_id,
        provider_id: this.provider_id,
      }).then(() => {
        this.$bvModal.hide("modal-generating-snapshot");
        this.hasSnapshot = true;
        this.getSnapshot();
      });
    },
  },
  mounted() {
    this.service_id = this.$route.params.service_id;
    this.provider_id = this.$route.params.provider_id || this.$route.query.provider_id;
    this.getSnapshot();
  },
};
</script>
<style lang="scss" scoped>
#labor-lawsuits {
  height: 100%;

  .details {
    max-width: 360px;
  }
}
</style>
